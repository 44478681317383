


































































































































































import { EVENT_PROMO_FLAG, PAGE_CONTENT_TYPE, PRODUCTS } from '@/constant/Enums';
import { ServerResponse } from '@/services/response.types';
import { ProviderModel } from '@/store/product/types';
import { AboutModel, ContentModel, EventAndPromoModel, TestimonialModel } from '@/store/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, State } from 'vuex-class';
import filters from "@/constant/Filters";

@Component({
  name: 'Dashboard',
  components: {
    protectNow: () => import('@/components/ProtectNow.vue'),

    compareMobil: () => import('@/components/formCompare/compareCar.vue'),
    compareHaji: () => import('@/components/formCompare/compareHaji.vue'),
    compareKesehatan: () => import('@/components/formCompare/compareHealtProtection.vue'),
    compareJiwa: () => import('@/components/formCompare/compareLifeInsurance.vue'),
    compareMotor: () => import('@/components/formCompare/compareMotorcylce.vue'),
    compareKecelakaanDiri: () => import('@/components/formCompare/comparePersonalAccident.vue'),
    compareProperti: () => import('@/components/formCompare/compareProperty.vue'),
  },
  mixins: [],
  filters
})
export default class Home extends Vue {
  dateformat!: (date: string, format: string) => string;

  @Action('GetConfig') GetConfig!: () => Promise<ServerResponse>;
  @Action('GetProvider', { namespace: 'product' }) GetProvider!: () => Promise<ServerResponse>;
  @Action('GetListTestimonials') GetListTestimonials!: () => Promise<ServerResponse>;
  @Action('GetAbout') GetAbout!: () => Promise<ServerResponse>;
  @Action('EventAndPromo') EventAndPromo!: () => Promise<ServerResponse>;

  @State('Providers', { namespace: 'product' }) Providers!: ProviderModel[];
  @State('testimonial') testimonial!: TestimonialModel[];
  @State('abouts') abouts!: AboutModel[];
  @State('eventpromo') eventpromo!: EventAndPromoModel[];
  @State('page_content') page_content!: ContentModel[];

  public showDetail = false;

  public get availableProduct() {
    return PRODUCTS;
  }


  public get dashboardContent(): ContentModel {
    return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.DASHBOARD)[0] ?? new ContentModel;
  }

  public get dashbordAboutUs(): ContentModel {
    return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.ABOUT_US)[0] ?? new ContentModel;
  }

  public get listProvider(): ProviderModel[][] {
    let list: ProviderModel[][] = [];
    let split = 3;
    let chunkLen = Number((this.Providers.length / split).toFixed());

    for (let i = 0; i < this.Providers.length; i += chunkLen) {
      list.push(this.Providers.slice(i, i + chunkLen));
    }
    return list;
  }

  public get testimonials(): TestimonialModel[][] {
    let list: TestimonialModel[][] = [];
    let split = 3;
    let chunkLen = Number((this.testimonial.length / split).toFixed());

    for (let i = 0; i < this.testimonial.length; i += chunkLen) {
      list.push(this.testimonial.slice(i, i + chunkLen));
    }
    return list;
  }


  public get eventAndPromo(): EventAndPromoModel[] {
    return this.eventpromo.filter((v) => {
      if (v.flag !== null) return [EVENT_PROMO_FLAG.EVENT, EVENT_PROMO_FLAG.PROMO, EVENT_PROMO_FLAG.NEWPROMO].indexOf(v.flag) >= 0 && v.status === 2;
      else return false;
    });
  }

  public selectedEventPromo: EventAndPromoModel | null = null;
  public get eventPromoSliders(): EventAndPromoModel[][] {
    let chunkSize = 2;
    let chunks: EventAndPromoModel[][] = [];
    for (let i = 0; i < this.eventAndPromo.length; i += chunkSize) {
      chunks.push(this.eventAndPromo.slice(i, i + chunkSize));
    }
    return chunks;
  }


  public get aboutApp(): AboutModel {
    return this.abouts.filter((v) => v.id === 0)[0];
  }

  public selectedProduct = PRODUCTS[0];
  public get formCompareComponent(): string {
    switch (this.selectedProduct.name) {
      case 'Mobil':
        return `compareMobil`;
      case 'Motor':
        return `compareMotor`;
      case 'Properti':
        return `compareProperti`;
      case 'Kecelakaan Diri':
        return `compareKecelakaanDiri`;
      case 'Jiwa':
        return `compareJiwa`;
      case 'Kesehatan':
        return `compareKesehatan`;
      case 'Haji':
        return `compareHaji`;
      default:
        return `compareMobil`;
    }
  }

  public get listBannerInfo(): ContentModel[] {
    return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.ABOUT_US && item.description.length > 0);
  }

  public created(): void {
    this.init();
  }

  private init() {
    this.GetProvider()
      .then((res) => {
        this.GetConfig();
        this.GetListTestimonials();
        this.GetAbout();
        this.EventAndPromo();

      })
      .catch((res) => (res?.status?.is_valid === false ? this.init() : false));
  }

  public mounted(): void {
    // on componenet mounted
  }

  /**
   * OnShowPromoDetail
   */
  public OnShowPromoDetail(item: EventAndPromoModel) {
    this.showDetail = true;
    this.selectedEventPromo = item;
  }

}
